import { VALUE } from "@/domain/constants/Value";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";

const jwtTokenManager = new JwtTokenManager();

export default class JwtHelper {
  public static isLoggin(): boolean {
    const token = jwtTokenManager.getToken();
    if (token !== null && token.trim() !== VALUE.NULL_STRING) {
      return true;
    } else {
      return false;
    }
  }
}
