import axios, { AxiosInstance } from "axios";
import { JwtTokenManager } from "../storage/JwtTokenManager";
import { App } from "vue";
import VueAxios from "vue-axios";
import { environment } from "../../../environment";
import JwtHelper from "../helper/JwtHelper";

const jwtTokenManager = new JwtTokenManager();

export class DefaultHttpClient {
  public static vueInstance: App;

  public static addResponseBodyInterceptor(axios: AxiosInstance): void {
    axios.interceptors.response.use(
      (response) => response.data,
      (error) => Promise.reject(error)
    );
  }

  public static addCacheDisablingInterceptor(axios: AxiosInstance): void {
    axios.interceptors.request.use(
      (request) => {
        request.params = { ...request.params, timestamp: Date.now() };
        return request;
      },
      (error) => Promise.reject(error)
    );
  }

  public static addRequestWithCredentials(axios: AxiosInstance): void {
    axios.interceptors.request.use(
      (request) => {
        request.withCredentials = true;
        return request;
      },
      (error) => Promise.reject(error)
    );
  }

  public static addAuthInterceptor(axios: AxiosInstance): void {
    if (JwtHelper.isLoggin()) {
      axios.interceptors.request.use(
        (config) => {
          const token = jwtTokenManager.getToken();
          if (token) {
            //  this.setHeader();
            config.headers["Authorization"] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  }

  public static setHeader(): void {
    const jwtTokenManager = new JwtTokenManager();
    if (jwtTokenManager.getToken()) {
      DefaultHttpClient.vueInstance.axios.defaults.headers.common[
        "Authorization"
      ] = "Bearer " + jwtTokenManager.getToken();
    }
  }

  private static createAxiosInstance(): AxiosInstance {
    const instance = axios.create({
      baseURL: environment.baseApiUrl,
      withCredentials: true,
    });

    //this.addResponseBodyInterceptor(instance);
    this.addCacheDisablingInterceptor(instance);
    // this.addAuthInterceptor(instance);
    this.addRequestWithCredentials(instance);

    return instance;
  }

  public static async setInstance(app: App<Element>): Promise<AxiosInstance> {
    const instance = this.createAxiosInstance();

    DefaultHttpClient.vueInstance = app;
    DefaultHttpClient.vueInstance.use(VueAxios, instance);

    this.setHeader();

    return instance;
  }
}
