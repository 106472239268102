
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    new: String,
    loading: Boolean,
  },
  setup() {
    /*
    const store = useStore();
    const swalNotification = new SwalNotification();
    const sessionController = store.state.ControllersModule.sessionController;

    return {

    }
    */
  }
});
